import { onMounted, reactive, ref, watch } from 'vue'
import { useForm } from '@/use/Base/useForm'
import { useStore } from 'vuex'
import { Form } from '@/extension/Form/Form'
import { Alert } from '@/extension/Alert/Alert'

export function useCategory (categoryController) {
  const store = useStore()
  const form = new Form()
  const isWithoutChanges = ref(true)
  const isRecord = ref(false)
  const emailList = reactive([])

  const formData = reactive({
    blocks: reactive([
      {
        id: 1,
        email: '',
        categories: []
      }
    ])
  })

  const rules = {}

  onMounted(async () => {
    const user = store.getters['auth/getUser']
    formData.blocks[0].email = user.email

    await categoryController.getCategories()
    const response = await categoryController.getMyCategories()

    const listEmail = new Set()
    const keys = Object.keys(response)
    keys.forEach((key) => {
      listEmail.add(key)
    })

    listEmail.forEach((email) => {
      if (email === user.email) return
      createBlock(email)
    })

    form.writeData(formData.blocks)
    isRecord.value = true
  })

  watch(formData, () => {
    emailList.length = 0
    formData.blocks.forEach((block) => {
      emailList.push({
        email: block.email,
        id: block.id
      })
    })

    if (!isRecord.value) return
    if (formData.blocks.length !== form.oldFormData.length) {
      isWithoutChanges.value = false
      return
    }

    const changedEmail = formData.blocks.filter((block, idx) => block.email !== form.oldFormData[idx].email)
    if (changedEmail.length) {
      isWithoutChanges.value = false
    }
  })

  const formAction = async () => {
    if (isWithoutChanges.value && !Object.keys(checkChangeCategoryList()).length) return
    // const categories = store.getters['userCategory/getChangeCategoryList']
    const categories = {}
    formData.blocks.forEach(el => {
      categories[el.email] = el.categories.filter(cat => cat.active).map(el => el.id)
    })

    await categoryController.setCategories(categories)

    await store.dispatch('auth/syncUserCategories')

    store.commit('userCategory/clearChangeCategoryList')
    isWithoutChanges.value = true
  }

  const checkChangeCategoryList = () => {
    return store.getters['userCategory/getChangeCategoryList']
  }

  const createBlock = (email) => {
    formData.blocks.push({
      id: Math.random(),
      email,
      categories: []
    })
  }

  const deleteBlock = (item) => {
    const myCategory = store.getters['userCategory/getActiveCategoryList']

    formData.blocks = formData.blocks.filter(el => el.id !== item.id)
    store.commit('userCategory/deleteCategory', { email: item.email, categories: myCategory[item.email] })
  }

  const undo = async () => {
    if (isWithoutChanges.value && !Object.keys(checkChangeCategoryList()).length) return
    isWithoutChanges.value = true
    formData.blocks = form.oldFormData

    const categoryList = checkChangeCategoryList()

    formData.blocks.forEach((block) => {
      block.categories.forEach((category) => {
        if (categoryList[block.email] === undefined) return

        if (categoryList[block.email].includes(category.id)) {
          categoryList[block.email] = categoryList[block.email].filter(el => el !== category.id)
          category.active = !category.active
        }
      })
    })

    await Alert.show('success', 'Все настройки были отменены')
  }

  return {
    ...useForm(rules, formData, formAction),
    createBlock,
    deleteBlock,
    undo,
    form,
    emailList
  }
}
