<template>
  <div
    class="text-block"
    :class="{'text-block--gap-20' : fontSizeDescription === '38'}"
  >
    <h4 class="text-block__subtitle">
      {{ subtitle }}
    </h4>
      <a
        v-if="isLink || isEmail || isTel"
        class="text-block__link"
        :class="{'text-block__link--underline' : isLink || isEmail}"
        :href="href"
        :target="isLink ? '_blank' : ''"
      >
      {{ description }}
    </a>

    <p
      v-else
      class="text-block__description"
      :class="classNameDescription"
    >
      {{ description }}
    </p>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    subtitle: {
      type: String
    },
    description: {
      type: Array
    },
    fontSizeDescription: {
      type: String,
      required: false,
      default: '',
      validate (val) {
        return ['24', '38'].includes(val)
      }
    },
    isEmail: {
      type: Boolean,
      required: false,
      default: false
    },
    isLink: {
      type: Boolean,
      required: false,
      default: false
    },
    isTel: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup (props) {
    const classNameDescription = computed(() => {
      if (!props.fontSizeDescription) return ''
      return `text-block__description--fsz-${props.fontSizeDescription}`
    })

    const href = computed(() => {
      if (props.isLink) return 'http://www.' + props.description

      if (props.isEmail) return 'mailto:' + props.description

      if (props.isTel) return 'tel:' + props.description

      return '#'
    })

    return {
      classNameDescription,
      href
    }
  }
}
</script>
