import { useForm } from '@/use/Base/useForm'
import { emailValid } from '@/utils/validator/fields'
import { helpers } from '@vuelidate/validators'

export function useCategoryItem (item, listEmail) {
  const rules = {
    email: {
      ...emailValid,
      unique: helpers.withMessage('Такая почта уже указана', (val) => {
        const element = listEmail.find(el => el.email === val)
        if (element.id === item.id) return true
      })
    }
  }
  return {
    ...useForm(rules, item)
  }
}
