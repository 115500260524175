<template>
  <ul class="accordion">
    <li
      class="accordion__list-item"
      v-for="(item, i) in accordionList"
      :key="item.id"
    >
      <component
        :class="openItemId === item.id ? 'accordion__item--open' : ''"
        :is-open="openItemId === item.id"
        :is="'VAccordionItem' + type"
        :is-coef-view="isCoefView"
        :item="item"
        :count="i + 1"
        @delete="$emit('delete', item)"
        @open="$emit('open', item)"
      />
    </li>
  </ul>

  <slot />
</template>

<script>
import VAccordionItemPosition from '@/components/ui/accordion/Decorators/Position/VAccordionItemPosition'
import VAccordionItemCategory from '@/components/ui/accordion/Decorators/Category/VAccordionItemCategory'

export default {
  emits: ['add', 'create', 'delete', 'open'],
  props: {
    openItemId: {
      type: Number,
      required: false
    },
    accordionList: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: false
    },
    isCoefView: {
      type: Boolean,
      required: false
    }
  },
  components: {
    VAccordionItemPosition,
    VAccordionItemCategory
  }
}
</script>
