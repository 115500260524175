import { RenderApiError } from '@/extension/Error/RenderApiError'

const type = new Map([
  ['string', ''],
  ['number', null],
  ['boolean', null],
  ['array', []],
  ['object', null]
])

export class Form {
  constructor () {
    this.handlerErrors = new RenderApiError()
    this.formData = {}
    this.oldFormData = {}
    this.isSend = false
  }

  send () {}

  writeData (formData) {
    const data = JSON.parse(JSON.stringify(formData))
    this.formData = formData
    this.oldFormData = data
  }

  cancelChangeForm () {
    this.formData = this.oldFormData
  }

  equalFormData () {
  }

  reset () {
    for (const key of this.formData) {
      const typeKey = typeof key
      if (!type.has(typeKey)) {
        throw new Error('Not expected type')
      }
      this.formData[key] = type.get(typeKey)
    }
  }
}
