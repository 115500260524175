<template>
  <div
    class="accordion__item"
    :class="isOpenAccordion ? 'accordion__item--open' : ''"
  >
    <div class="accordion__wrap">
      <div class="accordion__header">
        <div class="accordion__head">
          <div>
            <span class="accordion__count">Позиция №{{ count }}</span>
            <h3 class="accordion__title">{{ item.name }}</h3>
          </div>
          <button
            class="btn accordion__btn"
            @click="toggleAccordion"
          >
            <VIcon class="accordion__btn-icon" width="15" height="9" icon-name="AccordionArrow"/>
          </button>
        </div>
      </div>
      <transition name="accordion-animation">
        <div
          v-if="isOpenAccordion"
          class="padding"
        >
          <div class="accordion__body">

            <VTextBlock
              subtitle="Характеристика товара/услуги"
              :description="item.characteristic"
            />

            <VTextBlock
              subtitle="Единица измерения"
              :description="item.measurement.name"
            />

            <VTextBlock
              subtitle="Количество"
              :description="item.quantity"
            />
          </div>
          <h4 class="text-block__subtitle mt-60 mb-40">
            Дополнительные параметры
          </h4>
          <div class="accordion__body">
            <VTextBlockMoreDescription
              v-for="card in item.parameters.filter(par => par.is_criterion && !par.hide)"
              :key="card"
              :description="card.full_name"
            />
          </div>

          <VTable
            v-if="isCoefView && rows.length"
            :head-row="headBidder"
            :rows="rows"

            :is-min-height="false"
            :is-checkbox="false"
            class-head="border"
          />
        </div>
      </transition>
    </div>
    <div class="accordion__footer">
      <div class="accordion__footer-item">
        <span class="accordion__footer-text">
          Начальная цена:
        </span>
        <span class="accordion__price">
          {{ formatCurrency(item.price) }} руб.
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import VIcon from '@/components/ui/icon/VIcon'
import VTextBlock from '@/components/textblock/VTextBlock'
import { ref } from 'vue'
import VTextBlockMoreDescription from '@/components/VTextBlockMoreDescription'
import VTable from '@/components/ui/table/VTable'
import { usePositionTable } from '@/use/Form/Lot/Position/usePostitionTable'
import { formatCurrency } from '@/utils/format/number'

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    count: {
      type: Number,
      required: true
    },
    isCoefView: {
      type: Boolean,
      required: false
    }
  },
  setup (props) {
    const isOpenAccordion = ref(false)
    const toggleAccordion = () => {
      isOpenAccordion.value = !isOpenAccordion.value
    }
    return {
      ...usePositionTable(props.item),
      isOpenAccordion,
      toggleAccordion,
      formatCurrency
    }
  },
  components: {
    VTextBlockMoreDescription,
    VIcon,
    VTextBlock,
    VTable
  }
}
</script>

<style scoped>
.padding {
  padding: 20px 20px 30px;
}
</style>
