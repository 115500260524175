<template>
  <VCheckbox
    class="form__item form-checkbox form--double-item"
    :id="String(category.id)"
    :name="category.name ? category.name : 'temporary'"
    class-label="label label-border"
  >
    <template #default="slotProps">
      <label :for="slotProps.for" :class="slotProps.class">
        {{ category.name }}
      </label>
    </template>
  </VCheckbox>
</template>

<script>
import VCheckbox from '@/components/ui/form/Checkbox/VCheckbox'
export default {
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  components: {
    VCheckbox
  }
}
</script>
