<template>
  <div
    class="accordion__item"
  >
    <div class="accordion__wrap">
      <div class="accordion__header">
        <div class="accordion__head">
          <h3 class="accordion__title">Подключено для уведомлений почты:</h3>
          <div class="btns">
            <button
              v-if="count !== 1"
              class="btn accordion__btn accordion__btn--delete"
              @click="$emit('delete', item)"
            >
              <VIcon icon-name="AccordionDelete"/>
            </button>
            <button
              class="btn accordion__btn"
              @click="toggleAccordion"
            >
              <VIcon class="accordion__btn-icon" width="15" height="9" icon-name="AccordionArrow"/>
            </button>
          </div>
        </div>

        <div class="mt-40">
          <VInput
            name="email"
            id="email"
            :placeholder="`Адрес ${count === 1 ? 'основной ' : ''}электронной почты`"
            class-input="input input-border-all"
            class-form="form__control--category"
            v-model="item.email"
            :errors="v$.email.$errors"
            @blur="validateField"
            @input="validateField"
          />
        </div>
      </div>
      <transition name="accordion-animation">
        <div
          v-if="isOpen"
          class="padding"
        >
          <div>
            <h2 class="accordion__title mb-40">Выбрано из категорий:</h2>

            <form class="form" >
              <div class="form__inner form--double">
                <VCategory
                  v-for="category in item.categories"
                  :key="category.id"
                  v-model="category.active"
                  :category="category"
                  @input="changeCategoryItem(category.id)"
                />
              </div>
            </form>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import VIcon from '@/components/ui/icon/VIcon'
import { computed, inject, reactive, watch } from 'vue'
import VInput from '@/components/ui/form/VInput'
import VCategory from '@/components/views/Organizer/VOrganizerCategory'
import { useCategoryItem } from '@/use/Form/Bidder/categories/useCategoryItem'
import { useStore } from 'vuex'

export default {
  emits: ['delete', 'open'],
  props: {
    item: {
      type: Object,
      required: true
    },
    count: {
      type: Number,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: false
    }
  },
  setup (props, { emit }) {
    const categories = reactive([])
    const emailLList = inject('emailList')
    const store = useStore()
    const toggleAccordion = () => {
      if (!props.item.categories.length) {
        setCategories()
      }
      emit('open', props.item)
    }

    const getMyCategory = computed(() => {
      return store.getters['userCategory/getActiveCategoryList']
    })

    watch(getMyCategory, () => {
      setCategories()
    })

    function setCategories () {
      addAllCategory()
      const categoryList = getCategoryListIdByCurrentEmail()
      categories.forEach(category => {
        if (!categoryList) return

        if (categoryList.has(category.id)) {
          category.active = true
        }
      })
      props.item.categories = categories
    }

    function addAllCategory () {
      const category = JSON.parse(JSON.stringify(store.getters['userCategory/getCategoryList']))
      if (!category.length) return
      categories.push(...category)
      props.item.categories = categories
    }

    function getCategoryListIdByCurrentEmail () {
      const currentItemCategoryIdList = new Set()
      if (!getMyCategory.value?.[props.item.email]) return
      getMyCategory.value[props.item.email].forEach(cat => currentItemCategoryIdList.add(cat.id))
      return currentItemCategoryIdList
    }

    const changeCategoryItem = (id) => {
      store.commit('userCategory/changeCategoryList', { email: props.item.email, id })
    }

    return {
      ...useCategoryItem(props.item, emailLList),
      changeCategoryItem,
      toggleAccordion
    }
  },
  components: {
    VInput,
    VIcon,
    VCategory
  }
}
</script>

<style scoped>
.padding {
  padding: 20px 20px 30px;
}

.btns {
 height: 56px;

 display: flex;
 justify-content: center;
 align-items: center;
}

</style>
