<template>
  <VAccordionList
    :open-item-id="openItemId"
    :accordion-list="formData.blocks"
    type="Category"
    @open="handlerOpenItem"
    @delete="deleteBlock"
  >
    <VButton
      type="button"
      mod="plus"
      class="btn-primary-width-100 btn-width-100 mt-20"
      @click="createBlock('')"
    >
      Добавить почту
    </VButton>
  </VAccordionList>

  <div class="form__flex form__buttons mt-40">
    <button
      class="btn btn-primary btn-bg-green mr-10"
      type="button"
      :disabled="isSend"
      @click="onSubmit"
    >
      Сохранить
    </button>
    <button
      class="btn btn-primary btn-bg-transparent"
      type="button"
      @click="undo"
    >
      Отмена
    </button>
  </div>
</template>

<script>
import VAccordionList from '@/components/ui/accordion/VAccordionList'
import { useCategory } from '@/use/Form/Bidder/useCategory'
import VButton from '@/components/ui/buttons/VButton'
import { inject, provide, ref } from 'vue'
import { CATEGORY_CONTROLLER } from '@/utils/consts'

export default {
  setup () {
    const categoryController = inject(CATEGORY_CONTROLLER)
    const { createBlock, onSubmit, undo, deleteBlock, formData, isSend, emailList } = useCategory(categoryController)
    const openItemId = ref(1)

    const handlerOpenItem = (val) => {
      if (openItemId.value === val.id) {
        openItemId.value = null
      } else {
        openItemId.value = val.id
      }
    }

    provide('emailList', emailList)

    return {
      createBlock,
      onSubmit,
      undo,
      deleteBlock,
      formData,
      isSend,
      openItemId,
      handlerOpenItem
    }
  },
  components: {
    VButton,
    VAccordionList
  }
}
</script>

<style lang="scss" scoped>
.form {
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0;
    padding: 0;
    max-width: 1068px;
  }
}
</style>
